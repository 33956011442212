import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core'
import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  IdTokenClaims,
  InteractionStatus,
} from '@azure/msal-browser'
import { filter, takeUntil } from 'rxjs/operators'
import { UserManagementService } from '../../service/user-management.service'
import { AuthService } from '../../service/auth.service'
import { AUTH_SERVICE } from '../app.config'
import { GroupModel, MemberModel } from '../../models/member-model'
import { Subject } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  standalone: false,
})
export class HomeComponent implements OnInit, OnDestroy {
  _destroying$ = new Subject<void>()
  public isLoggedIn = false
  public isAuthorized = false
  private destroyRef = inject(DestroyRef)

  constructor(
    private authService: AuthService,
    public readonly userManagementService: UserManagementService,
  ) {}

  public ngOnInit(): void {
    console.log('enter ngOnInit in home component')
    const subscription = this.authService.activeAccountChanges.subscribe(
      (account: AccountInfo) => {
        this.isLoggedIn = this.authService.loginDisplay
        this.isAuthorized = (account.idTokenClaims as any).extension_isAdmin
        console.log('account', account)
        console.log('Active account changed from HomeComponent')
        this.getUser(account)
      },
    )
    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe()
    })
    this.setLoginDisplay()
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined)
    this._destroying$.complete()
  }

  public setLoginDisplay() {
    this.isLoggedIn = this.authService.loginDisplay
    const token = this.authService.getAccessToken()
    console.log('enter setLoginDisplay')
  }

  public async getUser(account: AccountInfo) {
    const token = await this.authService.getAccessToken()
    this.userManagementService.setToken(token)
    const response = this.userManagementService.getUser().subscribe({
      error: (err: HttpErrorResponse) => {
        this.isAuthorized = false
      },
    })
  }
}
