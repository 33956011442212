import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  output,
  Output,
} from '@angular/core'

@Component({
  selector: 'app-dropdown-multi',
  templateUrl: './dropdown-multi.component.html',
  styleUrl: './dropdown-multi.component.scss',
  standalone: false,
})
export class DropdownMultiComponent implements OnInit {
  @Input() public items: string[] = ['test', 'test2', 'test3']
  @Input() public selected: string[] = []
  selectionChange = output<string[]>()

  ngOnInit(): void {
    if (this.items === undefined || this.items.length === 0) {
      this.selected = []
    } else {
      if (!Array.isArray(this.selected)) {
        this.selected = [this.selected] // Convert single string to an array
      }
      this.selected = this.selected.filter((item) => this.items.includes(item))
    }
  }

  onItemSelect(item: string) {
    if (this.selected.includes(item)) {
      this.selected = this.selected.filter((i) => i !== item)
    } else {
      this.selected.push(item)
      this.selected.sort()
    }
    this.selectionChange.emit(this.selected)
  }

  areSelectedItemsIncludetInItems(): boolean {
    return this.selected.filter((item) => this.items.includes(item)).length > 0
  }
}
