import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './home/home.component'

const routes: Routes = [
  {
    // The home component allows anonymous access
    path: '',
    component: HomeComponent,
  },
  /* Changes end here. */
]

@NgModule({
  /* Changes start here. */
  // Replace the following line with the next one
  //imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  /* Changes end here. */
  exports: [RouterModule],
})
export class AppRoutingModule {}
