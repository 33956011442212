import { Component, inject } from '@angular/core'

import { NgTemplateOutlet } from '@angular/common'
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap'
import { ToastService } from '../../../service/app-toast.service'

@Component({
  selector: 'app-toasts',
  standalone: true,
  imports: [NgbToastModule, NgTemplateOutlet],
  templateUrl: './toasts-container.component.html',
  styleUrl: 'toasts-container.component.scss',
  host: {
    class: 'toast-container position-fixed top-0 end-0 mt-4 me-2',
    style: 'z-index: 1200',
  },
})
export class ToastsContainer {
  toastService = inject(ToastService)
}
